import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';
import Fondatori from '../components/Fondatori/Fondatori';
import MainLayout from '../layaouts/MainLayout/MainLayout';

function FondatoriPage({ pageContext }) {
  const {
    data,
  } = pageContext;

  return (
    <MainLayout>
      <Fondatori data={data} />
    </MainLayout>
  );
}

export default injectIntl(FondatoriPage);
