import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import './style.css';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import albero from '../../assets/icons/albero_white.svg';
import arrR from '../../assets/icons/arrR.svg';
import Button from '../Forms/Button/Button';
import ImageBlock from '../ImageBlock/ImageBlock';
import BlockLayout from '../../layaouts/BlockLayout/BlockLayout';
import MainCarusel from '../Forms/MainCrusel/MainCarusel';
import Carusel from '../Forms/Carusel/Carusel';
import MetaTags from '../Header/MetaTags';

function Fondatori({ data }) {
  const breakpoints = useBreakpoint();
  const intl = useIntl();
  const {
    foundationHero,
    promotersLogo,
    companyBlock,
    whyAcutis,
    founders,
    descriptionParagraph,
  } = data[intl.locale].acf;

  const { yoast_head_json } = data[intl.locale];

  const [collapse, setCollapse] = useState(true);
  const btnLabel = collapse
    ? intl.formatMessage({ id: 'btn_vedi_di_piu' })
    : intl.formatMessage({ id: 'btn_vedi_di_meno' });

  useEffect(() => {
    const mylist = document.getElementById('fondatori');
    const divs = mylist.children;
    const listitems = [];
    for (let i = 0; i < divs.length; i++) {
      listitems.push(divs.item(i));
    }

    listitems.sort((a, b) => {
      const compA = a.getAttribute('id');
      const compB = b.getAttribute('id');
      return compA < compB ? -1 : compA > compB ? 1 : 0;
    });
    for (let i = 0; i < listitems.length; i++) {
      mylist.appendChild(listitems[i]);
    }
  }, []);

  return (
    <>
      <MetaTags data={yoast_head_json} />
      <div id="fondatori" style={{ backgroundColor: '#f8f9fa' }}>
        {(foundationHero.imageBackgroundFoundationHero && foundationHero.title) && (
        <div
          style={{ marginBottom: !breakpoints.sm ? 170 : 100 }}
          id={foundationHero.positionFoundationHero}
        >

          {(promotersLogo.firstLogo && promotersLogo.secondLogo) && (
          <div className="over">
            <div className="promImgContainer">
              <img
                alt="pomotoriIMG"
                src={promotersLogo.firstLogo}
                className="overImg"
              />
            </div>
            <div className="promImgContainer">
              <img
                alt="pomotoriIMG"
                src={promotersLogo.secondLogo}
                className="overImg"
              />
            </div>
          </div>
          )}
          <div>
            <ImageBlock
              isTitleH1
              type="3"
              title={foundationHero.title}
              img={foundationHero.imageBackgroundFoundationHero}
            />
          </div>
        </div>
        )}

        {(descriptionParagraph.paragraphTitle && descriptionParagraph.contentVisibile) && (
        <div className="collapsableTextBox" id={descriptionParagraph.positionParagraph}>
          <h2 className="titleBox">{descriptionParagraph.paragraphTitle}</h2>
          {descriptionParagraph.contentVisibile
        && (
          <div className="collapsable">
            <div className="collapsableText">
              <span
                dangerouslySetInnerHTML={{
                  __html: collapse
                    ? descriptionParagraph.contentVisibile
                    : descriptionParagraph.contentVisibile.concat(
                      descriptionParagraph.contentHidden,
                    ),
                }}
              />
            </div>

            {descriptionParagraph.contentHidden && (
              <Button
                handleClick={() => {
                  setCollapse(!collapse);
                }}
                id="collapseBtn"
                label={btnLabel}
                style={{
                  borderRadius: 0,
                  textTransform: 'uppercase',
                  color: 'black',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '14px',
                  lineHeight: '21px',
                }}
              >
                {collapse ? (
                  <img
                    alt="accordionIMG"
                    src={arrR}
                    style={{
                      marginLeft: 6,
                      width: '16px',
                      height: '16px',
                    }}
                  />
                ) : (
                  <img
                    alt="accordionIMG"
                    src={arrR}
                    style={{
                      marginLeft: 6,
                      width: '16px',
                      height: '16px',
                      transform: 'rotate(-180deg)',
                    }}
                  />
                )}
              </Button>
            )}
          </div>
        )}
        </div>
        )}

        {(companyBlock.companyTitle && companyBlock.companyBackgroundImage) && (
        <div id={companyBlock.positionCompany}>
          <ImageBlock
            type="2"
            isTitileH2
            title={companyBlock.companyTitle}
            img={companyBlock.companyBackgroundImage}
            label={companyBlock.companyDescription}
            btnlabel={companyBlock.companyButtonLabel}
          />
        </div>
        )}

        {(whyAcutis.blockTitleWhyAcutis && whyAcutis.firstIcon && whyAcutis.firstSubtitle && whyAcutis.firstParagraph) && (
        <div
          id={whyAcutis.positionWhyAcutis}
          style={{
            width: '100%',
            backgroundColor: '#f8f9fa',
            backgroundImage: `url(${albero})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: !breakpoints.md ? '80%' : '250%',
            backgroundPosition: !breakpoints.md ? '310% -100%' : '0% 120%',
          }}
        >
          <div className="middleContainer">
            {/* <img alt="pomotoriIMG" src={whyAcutis.backgroundImage}
        style={{ position: 'absolute', zIndex: 3 }} /> */}
            <h2 className="subtitle">{whyAcutis.blockTitleWhyAcutis}</h2>
            <BlockLayout
              isTitleH3
              icon={whyAcutis.firstIcon}
              title={whyAcutis.firstSubtitle}
            >
              <span
                className="paragraph"
                dangerouslySetInnerHTML={{ __html: whyAcutis.firstParagraph }}
              />
            </BlockLayout>
            {(whyAcutis.secondParagraph) && (
            <BlockLayout
              isTitleH3
              icon={whyAcutis.secondIcon}
              title={whyAcutis.secondSubtitle}
            >
              <span
                className="paragraph"
                dangerouslySetInnerHTML={{ __html: whyAcutis.secondParagraph }}
              />
            </BlockLayout>
            )}
            {(whyAcutis.thirdParagraph) && (
            <BlockLayout
              isTitleH3
              icon={whyAcutis.thirdIcon}
              title={whyAcutis.thirdSubtitle}
            >
              <span
                className="paragraph"
                dangerouslySetInnerHTML={{ __html: whyAcutis.thirdParagraph }}
              />
              <br />
              {' '}
              <br />
            </BlockLayout>
            )}
          </div>
          <div style={{
            backgroundColor: 'white',
            height: 20,
            width: '100%',
          }}
          />
        </div>
        )}

        {(founders.blockTitleFounders && founders.foundersSlider.length > 0) && (
        <div id={founders.positionFounders}>
          <BlockLayout
            icon={founders.blockLogoFounders}
            title={founders.blockTitleFounders}
            bgColor="white"
          >
            <Carusel
              component="iPoromotori"
              cards={founders.foundersSlider}
              width={!breakpoints.md ? 380 : 256}
              height={!breakpoints.md ? 580 : 400}
            />
          </BlockLayout>
        </div>
        )}

      </div>
    </>
  );
}

export default Fondatori;
